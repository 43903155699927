import { createMemoryHistory, createRouter } from 'vue-router'

import LoginView from '@/views/LoginView.vue'
import DashboardView from '@/views/DashboardView.vue'

const routes = [
  {
    path: '/',
    component: LoginView,
  },
  {
    path: '/dashboard',
    component: DashboardView,
    meta: {
      requiresAuth: true // Add meta field to indicate protected route
    }
  },
]

const router = createRouter({
  history: createMemoryHistory(),
  routes,
})

const getCookie = ((cname) => {
  let name = cname + "="
  let decodedCookie = decodeURIComponent(document.cookie)
  let ca = decodedCookie.split(';')
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) == ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ""
})

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    const secret = getCookie("secret")
    if (secret) {
      // User is authenticated, proceed to the route
      next();
    } else {
      // User is not authenticated, redirect to login
      next('/');
    }
  } else {
    // Non-protected route, allow access
    next();
  }
});

export default router

