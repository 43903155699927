<template>
  <div class="container text-center">
    <div class="row">
      <div class="col">
        <LoginForm />
      </div>
    </div>
  </div>
</template>

<script>
import LoginForm from '@/components/LoginForm.vue'

export default {
  name: 'LoginView',
  components: {
    LoginForm
  },

  data: function() {
  return {

    } // return
  } // data

}// export default
</script>

<style scoped>
.container {
  min-height: 100vh;

}
</style>
