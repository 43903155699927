import axios from 'axios'
import { helpers } from '@/js/helpers.js'

export const requests = {
  async axiosPost(url, data) {
    console.log(data)

    let username = helpers.readCookieValue("username")
    let secret = helpers.readCookieValue("secret")

    if(typeof(data) == 'undefined') {
      data = {"username": username, "secret": secret}
    }else {
      data["username"] = username
      data["secret"] = secret
    }

    try {
      const rsp = await axios({
        method: 'post',
        url: url,
        data: data,
        baseURL: 'https://thingsboard.spdns.de',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
      //console.log(rsp)
      return rsp.data

    } catch(e) {
      console.log("Error axios POST, url = " + url + " data:")
      console.log(data)
    }
  },

  /*
  async axiosGet(url) {
    try {
      const rsp = await axios({
        method: 'get',
        url: url,
        baseURL: 'https://thingsboard.spdns.de',
        headers: {
          'Content-Type': 'text/html; charset=utf-8',
          'Accept': 'application/json'
        }
      })
      //console.log(rsp)
      return rsp.data

    } catch (e) {
        console.log("Error axios GET, url = " + url)
        console.log(e)
    }
  },
  */

  add_auth(data) {
    let username = helpers.readCookieValue("username")
    let secret = helpers.readCookieValue("secret")

    console.log(username)
    console.log(secret)

    if(username == '' || secret == '') return data

    if(data == 'undefined') {
      data = {"username": username, "secret": secret}
    }else {
      data["username"] = username
      data["secret"] = secret
    }

    return data
  }
}
