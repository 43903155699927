<template>
  <!--
  <div class="container">
    <LineChartGenerator v-if="chartEnable" :data="chartData" :options="chartOptions" />
    <p v-else>No Data</p>
  </div>
  -->
  <LineChartGenerator v-if="chartEnable" :data="chartData" :options="chartOptions" />
</template>

<script>
import { Line as LineChartGenerator } from 'vue-chartjs'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  TimeScale,
  CategoryScale,
  PointElement
} from 'chart.js'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  TimeScale,
  CategoryScale,
  PointElement
)

ChartJS.defaults.color = 'black'

export default {
  name: 'LineChart',
  components: {
    LineChartGenerator
  },
  props: {
    chartData: {
        type: Object,
        default: () => {}
    },
    chartOptions: {
      type: Object,
      default: () => {}
    },
    chartEnable: {
      type: Boolean,
      default: false
    }
  },
}
</script>

<style scoped>
/*
.container {
  height: 100%;
  vertical-align: center;
}
*/
</style>
