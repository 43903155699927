<template>
  <div class="container-fluid main">
    <RouterView />
  </div>
</template>
<script>

export default {
  name: 'App',
  components: {
    //MainContainer
  }
}
</script>

<style>
.html,
.body {
  margin: 0;
  padding: 0;
}
.body {
  min-height:100%;
  width: 100vw;
}
* {
  box-sizing: border-box;
}
.main {
  height: 100%;
  width: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /*color: #2c3e50;*/
  background-color: #5A6C7A;
  padding-top: 60px;
  height: 100%;
  width: 100%;
}
</style>
