/* eslint-disable */
<template>
  <div class="main container-fluid">
    <h1>"Environment Sensors"</h1>
    <div class="row main-row">
        <div class="col data-col">
          <div class="row">
              <!--
              <div class="col">
                <RouterLink to="/"><button class="btn btn-secondary">Login</button></RouterLink>
                <button class="btn btn-secondary" @click="onClickLogout()">Logout</button>
              </div>
              -->
              <div class="col">
                <ul class="nav flex-column">
                  <li v-for="d in devices" v-bind:key="d.id" class="nav-item">
                    <a class="nav-link" @click="onClickDevUi(d.ui)">{{ d.ui.slice(-4) }}</a>
                  </li>
                </ul>
              </div>
          </div>
          <div class="row justify-content-md-center">
            <div class="col-sm-auto time-buttons" v-if="currentDevUi">
              <button type="button" class="btn btn-primary env-button" :disabled='disableDayBeforeBtn' @click="onClickDayBtn(-1)">Day before</button>
              <button type="button" class="btn btn-primary env-button" :disabled='disableDayAfterBtn' @click="onClickDayBtn(+1)">Day after</button>
              <button type="button" class="btn btn-primary env-button" :disabled='disableDayTodayBtn' @click="onClickDayBtn(0)">Today</button>
            </div>
          </div>
          <div class="row justify-content-md-center">
            <div class="col-sm-auto time-buttons" v-if="currentDevUi">
              <button type="button" class="btn btn-primary env-button" @click="onClickTimeBtn(1)">1h</button>
              <button type="button" class="btn btn-primary env-button" @click="onClickTimeBtn(3)">3h</button>
              <button type="button" class="btn btn-primary env-button" @click="onClickTimeBtn(6)">6h</button>
              <button type="button" class="btn btn-primary env-button" @click="onClickTimeBtn(12)">12h</button>
              <button type="button" class="btn btn-primary env-button" @click="onClickTimeBtn(24)">24h</button>
              <button type="button" class="btn btn-primary env-button" @click="onClickTimeBtn(48)">48h</button>
            </div>
          </div>
          <div class="row">
            <b v-if="currentDevUi">
              {{ currentDevUi.slice(-4) }}
            </b>
            <p v-if="noPacketsText">
              {{ noPacketsText }}
            </p>
            <p v-if="dataStartDate">
              {{dataStartDate.format('dddd DD.MM.')}}
              <span v-if="dataEndDateShow">
                - {{dataEndDate.format('dddd DD.MM.')}}
              </span>
              <br />
              <span v-if="dataStartTime && dataEndTime">
                {{dataStartTime}} - {{dataEndTime}}
              </span>
            </p>
            <!--
            <p v-if="dataStartTime && dataEndTime">
              {{dataStartTime}} - {{dataEndTime}}
            </p>
            -->
          </div>
          <div class="row justify-content-md-center">
            <div class="col-sm-auto" v-if="currentDevUi">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="cbFilterData" v-model="cbFilterDataChecked" @change="onClickFilterData">
                <label class="form-check-label" for="cbFilterData">Filter data</label>
              </div>
            </div>
          </div>
          <div class="row chart-container">
            <div class="col">
              <LineChart :chartEnable=temperatureChartEnable :chartData=temperatureChartData :chartOptions=temperatureChartOptions />
            </div>
            <div class="col">
              <LineChart :chartEnable=pressureChartEnable :chartData=pressureChartData :chartOptions=pressureChartOptions />
            </div>
            <div class="col">
              <LineChart :chartEnable=humidityChartEnable :chartData=humidityChartData :chartOptions=humidityChartOptions />
            </div>
          </div>
          <div class="row chart-container">
            <div class="col">
              <LineChart :chartEnable=luxChartEnable :chartData=luxChartData :chartOptions=luxChartOptions />
            </div>
            <div class="col">
              <LineChart :chartEnable=irChartEnable :chartData=irChartData :chartOptions=irChartOptions />
            </div>
            <div class="col">
              <LineChart :chartEnable=lightfsChartEnable :chartData=lightfsChartData :chartOptions=lightfsChartOptions />
            </div>
          </div>
          <div class="row">
            <table class="table data-table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Time</th>
                  <th scope="col">Battery</th>
                  <th scope="col">Temperature [°C]</th>
                  <th scope="col">Pressure [hPa]</th>
                  <th scope="col">Humidity [%]</th>
                  <th scope="col">Light Lux [counts]</th>
                  <th scope="col">Light IR [counts]</th>
                  <th scope="col">Light Full Spectrum [counts]</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="p in packets.slice()" v-bind:key="p.id">
                  <th scope="row">{{p.id}}</th>
                  <td>{{ formatDateTable(p.created_on) }}</td>
                  <td>{{p.battery}}</td>
                  <td>{{ formatTemperature(p.temperature) }}</td>
                  <td>{{ formatPressure(p.pressure) }}</td>
                  <td>{{p.rh}}</td>
                  <td>{{p.lux}}</td>
                  <td>{{p.ir}}</td>
                  <td>{{p.fs}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import 'chartjs-adapter-moment'
import LineChart from '@/components/LineChart.vue'

import { requests } from '@/js/requests.js'
import { helpers } from '@/js/helpers.js'

export default {
  name: 'DashboardView',
  components: {
    LineChart,
  },

  data: function() {
    return {
      devices: [],
      packets: [],
      packetsRaw: [],

      currentDevUi: '',

      webSocket: null,
      lastPacketFetch: null,

      chartWidth: "100%",

      dataEndDate: null,
      dataEndDateShow: true,
      dataStartDate: null,
      dataEndTime: null,
      dataStartTime: null,

      noPacketsText: null,

      disableDayBeforeBtn: false,
      disableDayAfterBtn: true,
      disableDayTodayBtn: true,

      ignoreNewPackets: false,

      temperatureChartData: null,
      temperatureChartOptions: null,
      temperatureChartEnable: false,

      pressureChartData: null,
      pressureChartOptions: null,
      pressureChartEnable: false,

      humidityChartData: null,
      humidityChartOptions: null,
      humidityChartEnable: false,

      luxChartData: null,
      luxChartOptions: null,
      luxChartEnable: false,

      irChartData: null,
      irChartOptions: null,
      irChartEnable: false,

      lightfsChartData: null,
      lightfsChartOptions: null,
      lightfsChartEnable: false,

      cbFilterDataChecked: false,

      testLoginTimer: null,
      testLoginTimerRunning: false,
    }
  },

  created() {
    this.getDevices()
  },

  beforeUnmount() {
    console.log("DashboardView beforeUnmount called!")
    this.wsClose()
  },

  computed: {

  },

  methods: {
    /*
    startLoginTimer() {
      this.testLoginTimer = setInterval(this.testLoginTimerFunction, 1000)
      this.testLoginTimerRunning = true
    },

    stopLoginTimer() {
      clearInterval(this.testLoginTimer)
      this.testLoginTimerRunning = false
    },

    testLoginTimerFunction() {
      console.log("testLoginTimerFunction")
      console.log(this.testLogin())
      if(this.testLogin() === false) {
        console.log("Coockie expired - logging out!")
        this.stopLoginTimer()
        //window.location.reload()
      }
    },
    */

    onSocketOpen(evt) {
      console.log("Socket is open!")
      console.log(evt.data)
    },

    onSocketMessage(evt) {
      console.log(evt.data)
      if(evt.data === "NewData") {
        if(!this.ignoreNewPackets) {
          this.getNewPackets()
        }
      }
    },

    onSocketError(evt) {
      console.log(evt.data)
    },

    wsSend() {
      if(this.webSocket !== null) {
        this.webSocket.send("Hello yuu!")
      }else {
        console.log("Not connected!")
      }
    },

    wsOpenPacketInfo(dev_ui) {
      if(this.webSocket == null) {
        this.webSocket = new WebSocket('wss://thingsboard.spdns.de/packetinfo/' + dev_ui)
        this.webSocket.onopen = this.onSocketOpen
        this.webSocket.onmessage = this.onSocketMessage
        this.webSocket.onerror = this.onSocketError
        this.lastPacketFetch = new Date()
      }
    },

    wsClose() {
      if(this.webSocket !== null) {
        this.webSocket.close()
      }
    },

    onClickLogout() {
      helpers.deleteCookies()
    },

    onClickFilterData() {
      if(this.cbFilterDataChecked) {
        var filtered = this.packets.filter((packet) => packet.pressure != 0)
        this.updateGraphs(filtered)
      }else {
        this.updateGraphs(this.packets)
      }
    },

    async onClickDevUi(dev_ui) {
      if(this.currentDevUi !== null && this.currentDevUi === dev_ui) return
      this.currentDevUi = dev_ui

      var yesterday = moment().subtract(1, 'days')
      this.dataEndDate = moment()

      this.getDevicePackets(dev_ui, yesterday, this.dataEndDate)

      this.wsOpenPacketInfo(dev_ui)
    },

    async onClickTimeBtn(hours) {
      var before = this.dataEndDate.clone().subtract(hours, 'hours')

      if(hours < 24) {
        var filtered = this.packets.filter((packet) => moment(packet.created_on).diff(before) >= 0)
        this.updateGraphs(filtered)
      }else if(hours == 24) {
        this.updateGraphs(this.packets)
      }else {
        this.getDevicePackets(this.currentDevUi, before, this.dataEndDate)
      }
    },

    async onClickDayBtn(d) {
      var now = moment()
      var before = this.dataEndDate.clone()

      if(d == 0) { // Today
        this.dataEndDate = now

        var yesterday = now.clone().subtract(1, 'days')

        this.getDevicePackets(this.currentDevUi, yesterday, now)

        this.disableDayAfterBtn = true
        this.disableDayTodayBtn = true
        this.ignoreNewPackets = false
      }else if(d == -1) { // One day back
        this.dataEndDate.subtract(1, 'days')
        before.subtract(2, 'days')

        this.getDevicePackets(this.currentDevUi, before, this.dataEndDate)

        this.disableDayAfterBtn = false
        this.disableDayTodayBtn = false
        this.ignoreNewPackets = true
      }else if(d == 1) { // One day ahead
        this.dataEndDate.add(1, 'days')

        this.getDevicePackets(this.currentDevUi, before, this.dataEndDate)

        if(this.dataEndDate.date() == now.date()) {
          this.disableDayAfterBtn = true
          this.disableDayTodayBtn = true
          this.ignoreNewPackets = false
        }
      }
    },

    async getDevicePackets(dev_ui, startDate, endDate) {
      var data = {'dev_ui': dev_ui, 'from': startDate, 'to': endDate}
      this.packets = await requests.axiosPost('/devices/' + this.currentDevUi + '/packets', data)

      if(this.packets.length > 0) {
        console.log(this.packets[0])
        this.noPacketsText = null
        this.updateGraphs(this.packets)

      }else {
        this.dataStartDate = null
        this.noPacketsText = "No packets for " + startDate.format('dddd DD.MM.')
      }
    },

    async getDevices() {
      this.devices = await requests.axiosPost('/devices')
    },

    async getNewPackets() {
      var now = new Date()

      var data = {'dev_ui': this.currentDevUi, 'from': this.lastPacketFetch, 'to': now}

      var new_packets = await requests.axiosPost('/devices/' + this.currentDevUi + '/packets', data)
      console.log(new_packets)
      new_packets.forEach((p) => this.packets.unshift(p))

      this.lastPacketFetch = new Date()

      this.updateGraphs(this.packets)
    },

    loadTableData() {
      this.updateGraphs(this.packets)
    },

    updateDateLabels(packets) {
      // Packets are sorted newest first
      var lastDate = packets[0].created_on
      var firstDate = packets[packets.length-1].created_on

      this.dataStartDate = moment(firstDate)

      if(this.dataStartDate.date() == this.dataEndDate.date()) {
        this.dataEndDateShow = false
      }else {
        this.dataEndDateShow = true
      }

      this.dataStartTime = moment(firstDate).format('HH:mm:ss')
      this.dataEndTime = moment(lastDate).format('HH:mm:ss')
    },

    updateGraphs(packets) {
      var labels = []
      var temps = []
      var press = []
      var humi = []
      var lux = []
      var ir = []
      var fs = []

      this.updateDateLabels(packets)

      for(var i = packets.length-1; i > 0; i--) {
        labels.push(packets[i].created_on)
        temps.push(this.formatTemperature(packets[i].temperature))
        press.push(this.formatPressure(packets[i].pressure))
        humi.push(packets[i].rh)
        lux.push(packets[i].lux)
        ir.push(packets[i].ir)
        fs.push(packets[i].fs)
      }

      labels.slice().reverse()
      temps.slice().reverse()
      humi.slice().reverse()
      lux.slice().reverse()
      ir.slice().reverse()
      fs.slice().reverse()

      this.updateTemperatureGraph(labels, temps)
      this.updatePressureGraph(labels, press)
      this.updateHumidityGraph(labels, humi)
      this.updateLuxGraph(labels, lux)
      this.updateIrGraph(labels, ir)
      this.updateLightfsGraph(labels, fs)
    },

    updateLightfsGraph(labels, data) {
      this.lightfsChartData = {
        labels: labels,
        datasets: [{
          label: 'Full Spectrum Light',
          data: data,
          borderColor: '#fff',
          borderWidth: 3,
          backgroundColor: '#fff',
          radius: 0,
          tension: 0.3,
        }]
      }

      if(this.lightfsChartEnable == false) {
        this.lightfsChartOptions = {
          interaction: {
            intersect: false,
          },
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: true
          },
          scales: {

            x: {
              type: 'time',
              time: {
                displayFormats: {
                  hour: 'HH:mm'
                },
              },
              ticks: {
                color: 'black',
                font: {
                  size: '11'
                },
                stepSize: 1,
              },
              grid: {
                display: false,
              },
              border: {
                display: false,
              },
            },

            y: {
              suggestedMax: 90,
              suggestedMin: 10,
              ticks: {
                color: 'black',
                font: {
                  size: '11'
                }
              },
              grid: {
                drawTicks: true,
              },
              border: {
                dash: [5, 10],
              },
            }
          },
        }
        this.lightfsChartEnable = true
      }
    },

    updateIrGraph(labels, data) {
      this.irChartData = {
        labels: labels,
        datasets: [{
          label: 'Infrared Light',
          data: data,
          borderColor: '#a41a27',//'#685A7A',
          borderWidth: 3,
          backgroundColor: '#a41a27',
          radius: 0,
          tension: 0.3,
        }]
      }

      if(this.irChartEnable == false) {
        this.irChartOptions = {
          interaction: {
            intersect: false,
          },
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: true
          },
          scales: {

            x: {
              type: 'time',
              time: {
                displayFormats: {
                  hour: 'HH:mm'
                },
              },
              ticks: {
                color: 'black',
                font: {
                  size: '11'
                },
                stepSize: 1,
              },
              grid: {
                display: false,
              },
              border: {
                display: false,
              },
            },

            y: {
              suggestedMax: 90,
              suggestedMin: 10,
              ticks: {
                color: 'black',
                font: {
                  size: '11'
                }
              },
              grid: {
                drawTicks: true,
              },
              border: {
                dash: [5, 10],
              },
            }
          },
        }
        this.irChartEnable = true
      }
    },

    updateLuxGraph(labels, data) {
      this.luxChartData = {
        labels: labels,
        datasets: [{
          label: 'Lux',
          data: data,
          borderColor: '#f1e907',//'#685A7A',
          borderWidth: 3,
          backgroundColor: '#f1e907',
          radius: 0,
          tension: 0.3,
        }]
      }

      if(this.luxChartEnable == false) {
        this.luxChartOptions = {
          interaction: {
            intersect: false,
          },
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: true
          },
          scales: {

            x: {
              type: 'time',
              time: {
                displayFormats: {
                  hour: 'HH:mm'
                },
              },
              ticks: {
                color: 'black',
                font: {
                  size: '11'
                },
                stepSize: 1,
              },
              grid: {
                display: false,
              },
              border: {
                display: false,
              },
            },

            y: {
              suggestedMax: 90,
              suggestedMin: 10,
              ticks: {
                color: 'black',
                font: {
                  size: '11'
                }
              },
              grid: {
                drawTicks: true,
              },
              border: {
                dash: [5, 10],
              },
            }
          },
        }
        this.luxChartEnable = true
      }
    },

   updateHumidityGraph(labels, data) {
      this.humidityChartData = {
        labels: labels,
        datasets: [{
          label: 'Humidity',
          data: data,
          borderColor: '#2f22da',//'#685A7A',
          borderWidth: 3,
          backgroundColor: '#2f22da',
          radius: 0,
          tension: 0.3,
        }]
      }

      if(this.humidityChartEnable == false) {
        this.humidityChartOptions = {
          interaction: {
            intersect: false,
          },
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: true
          },
          scales: {

            x: {
              type: 'time',
              time: {
                displayFormats: {
                  hour: 'HH:mm'
                },
              },
              ticks: {
                color: 'black',
                font: {
                  size: '11'
                },
                stepSize: 1,
              },
              grid: {
                display: false,
              },
              border: {
                display: false,
              },
            },

            y: {
              suggestedMax: 90,
              suggestedMin: 40,
              ticks: {
                color: 'black',
                font: {
                  size: '11'
                }
              },
              grid: {
                drawTicks: true,
              },
              border: {
                dash: [5, 10],
              },
            }
          },
        }
        this.humidityChartEnable = true
      }
    },

    updatePressureGraph(labels, data) {
      this.pressureChartData = {
        labels: labels,
        datasets: [{
          label: 'Pressure',
          data: data,
          borderColor: '#45c717',//'#2f22da',//'#685A7A',
          borderWidth: 3,
          backgroundColor: '#45c717', //'#2f22da',
          radius: 0,
          tension: 0.3,
        }]
      }

      if(this.pressureChartEnable == false) {
        this.pressureChartOptions = {
          interaction: {
            intersect: false,
          },
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: true
          },
          scales: {

            x: {
              type: 'time',
              time: {
                displayFormats: {
                  hour: 'HH:mm'
                },
              },
              ticks: {
                color: 'black',
                font: {
                  size: '11'
                },
                stepSize: 1,
              },
              grid: {
                display: false,
              },
              border: {
                display: false,
              },
            },

            y: {
              suggestedMax: 1200,
              suggestedMin: 900,
              ticks: {
                color: 'black',
                font: {
                  size: '11'
                }
              },
              grid: {
                drawTicks: true,
              },
              border: {
                dash: [5, 10],
              },
            }
          },
        }
        this.pressureChartEnable = true
      }
    },

    updateTemperatureGraph(labels, data) {
      this.temperatureChartData = {
        labels: labels,
        datasets: [{
          label: 'Temperature',
          data: data,
          borderColor: '#BF7427',//'#685A7A',
          borderWidth: 3,
          backgroundColor: '#BF7427',
          radius: 0,
          tension: 0.3,
        }]
      }

      if(this.temperatureChartEnable == false) {
        this.temperatureChartOptions = {
          interaction: {
            intersect: false,
          },
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: true
          },
          scales: {

            x: {
              type: 'time',
              time: {
                displayFormats: {
                  hour: 'HH:mm'
                },
              },
              ticks: {
                color: 'black',
                font: {
                  size: '11'
                },
                stepSize: 1,
              },
              grid: {
                display: false,
              },
              border: {
                display: false,
              },
            },

            y: {
              suggestedMax: 25,
              suggestedMin: 18,
              ticks: {
                color: 'black',
                font: {
                  size: '11'
                }
              },
              grid: {
                drawTicks: true,
              },
              border: {
                dash: [5, 10],
              },
            }
          },
        }
        this.temperatureChartEnable = true
      }
    },

    formatTemperature(temp) {
        return parseFloat(temp/100.0).toFixed(2)
    },

    formatPressure(pres) {
      return parseFloat(pres/10.0).toFixed(2)
    },

    formatDateTable(date) {
      return moment(date).format("DD-MM HH:mm:ss")
    },
    formatDateGraph(date) {
      return moment(date).format("YYYY-MM-DDTHH:mm:ss")
    }

  }
}
</script>

<style scoped>
.chart-container {
  min-height: 500px;
  /*min-width: 500px;*/
}
.main {
  min-height: 100vh;
}
.main-row {
  border-style: solid;
  border-color: #5A5C7A;
  min-height: 100vh;
  width: 100%;
}
.nav-col {
  border-right: solid;
  border-color: #5A5C7A;
}
.data-col {
  padding: 0;
}
.data-table {
  font-size: 11px;
  --bs-table-bg: #685A7A;
  --bs-table-border-color: #302938;
}
a {
  color: #302938;
}
a:hover {
  color: #685A7A;
}
.time-buttons {
  display: flex;
  gap: 8px;
}
.env-button {
  --bs-btn-bg: #685A7A;
  --bs-btn-border-color: #302938;
  --bs-btn-hover-bg: #5A5C7A;
  --bs-btn-hover-border-color: #302938;
  --bs-btn-active-bg: #85739c;
  --bs-btn-active-border-color: #302938;
  --bs-btn-disabled-bg: #302938;
  --bs-btn-disabled-border-color: #302938;

  /*
	--bs-btn-color: #fff;
	--bs-btn-bg: #0d6efd;
	--bs-btn-border-color: #0d6efd;
	--bs-btn-hover-color: #fff;
	--bs-btn-hover-bg: #0b5ed7;
	--bs-btn-hover-border-color: #0a58ca;
	--bs-btn-focus-shadow-rgb: 49,132,253;
	--bs-btn-active-color: #fff;
	--bs-btn-active-bg: #0a58ca;
	--bs-btn-active-border-color: #0a53be;
	--bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	--bs-btn-disabled-color: #fff;
	--bs-btn-disabled-bg: #0d6efd;
	--bs-btn-disabled-border-color: #0d6efd;
}
*/
}
</style>
