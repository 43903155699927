import Vuex from 'vuex'

const store = new Vuex.Store({
  state: {
    user_data: {
      id: 17777,
      secret: ''
    }
  },
  mutations: {

  }
})

export default store
