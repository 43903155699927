<template>
  <div>
    <form @submit.prevent="onClickLogin()" class="row needs-validation justify-content-center" novalidate>
      <div class="col-sm-2">
        <div class="row">
          <label for="inputUsername" class="form-label">Username</label>
          <input v-model="inputUsername" type="text" class="form-control" id="inputUsername" required>
        </div>
        <div class="row">
          <label for="inputPassword" class="form-label">Password</label>
          <input v-model="inputPassword" type="password" class="form-control" id="inputPassword" required>
        </div>
        <div class="row">
          <div>
            <button :disabled="secretSet" type="submit" class="btn btn-primary">Login</button>
          </div>
        </div>
      </div>
    </form>
    <div>
      <button class="btn btn-secondary" @click="testLogin()">Test</button>
      <RouterLink to="/dashboard"><button class="btn btn-secondary">Dashboard</button></RouterLink>
    </div>
  </div>
</template>

<script>
import { requests } from '@/js/requests.js'
import { helpers } from '@/js/helpers.js'
//import moment from 'moment'

export default {
  name: 'LoginForm',
  components: {
    //LoginButton
  },
  props: {
    //title: String
  },
  computed: {
    secretSet() {
      //return this.testLogin()
      return false
    }
  },
  data: function() {
    return {
      inputUsername: '',
      inputPassword: '',
    }
  },
  mounted() {
    this.setupFormValidation()
  },
  beforeUnmount() {
    //if()
    //this.stopLoginTimer()
  },
  methods: {
    async onClickLogin() {
      let username = this.inputUsername
      let password = this.inputPassword

      if(username == '' || password == '') return false

      var rsp = await requests.axiosPost('/login', {"user": username, 'password': password})
      if(typeof(rsp) != 'undefined' && rsp.error == "none") {
        this.setCookie(username, rsp.secret)
        this.$router.push("/dashboard")
      }else {
        console.log("Failure")
        // TODO: invalidate form
      }
    },

    setCookie(username, secret) {
      let d = new Date()
      d.setTime(d.getTime() + 12 * 60 * 60 * 1000)

      let expires = "expires=" + d.toUTCString()

      document.cookie = "username=" + username + ";" + expires + ";path=/"
      document.cookie = "secret=" + secret + ";" + expires + ";path=/"
    },

    testLogin() {
      let secret = helpers.readCookieValue("secret")
      if(secret != '') {
        console.log(secret)
        return true
      }else {
        console.log("No coockie")
        return false
      }
    },

    setupFormValidation() {
      // Fetch all the forms we want to apply custom Bootstrap validation styles to
      var forms = document.querySelectorAll('.needs-validation')

      // Loop over them and prevent submission
      Array.prototype.slice.call(forms)
        .forEach(function (form) {
          form.addEventListener('submit', function (event) {
            if (!form.checkValidity()) {
              event.preventDefault()
              event.stopPropagation()
            }

            form.classList.add('was-validated')
          }, false)
      })
    },

  } // methods
} // export default

</script>

<style scoped>
.row {
  padding-bottom: 10px;
}
.btn {
  margin-right: 5px;
}
</style>
